<template>
    <span class="pl-2">
        <span @click="openDialog()">
            <AppButtonRounded :icon="icon" />
        </span>
        <AppDialog
            fullscreen
            :title="title"
            :dialog.sync="open"
            @close-dialog="closeDialog()"
            max-width="500px"
        >
            <DriveIndex :filter="driveFilter" :filter_read="driveFilterRead" />
        </AppDialog>
    </span>
</template>

<script>
    import dialogMixin from '~/mixins/dialogMixin.js'

    export default {
        mixins: [dialogMixin],
        props: {
            item: Object,
        },
        data() {
            return {
                icon: 'cloud',
                title: null,
            }
        },
        computed: {
            projectId() {
                const { project } = this.item
                return project?.id || this.item.id
            },
            driveFilter() {
                const inter = this.isInterventionPage
                    ? { intervention_id: this.item.id }
                    : {}

                return { project_id: this.projectId, ...inter }
            },
            driveFilterRead() {
                return { project_id: this.projectId }
            },
            isInterventionPage() {
                return this.$route.name === 'manager.interventions'
            },
        },
        mounted() {
            this.title = `${this.item.customer.firstName} ${this.item.customer.lastName}`
        },
    }
</script>
