<template>
  <v-navigation-drawer v-model="drawer" dark app>
    <v-list expand nav>
      <v-list-item :to="item.to" color="primary" v-for="(item, i) in items">
        <v-list-item-icon v-if="!item.icon">
          <v-icon>mdi-circle-small</v-icon>
        </v-list-item-icon>
        <v-list-item-icon v-if="item.icon">
          <v-icon v-text="`${item.icon}`" />
        </v-list-item-icon>

        <v-list-item-content v-if="item.title">
          <v-list-item-title v-text="item.title" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { get, sync } from "vuex-pathify"

export default {
  computed: {
    items: get("adminapp/items"),
    drawer: sync("adminapp/drawer"),
  },
}
</script>
