<template>
    <div>
        <v-autocomplete
            v-model="selectedResult"
            :items="results"
            :loading="isLoading"
            :search-input.sync="search"
            label="Rechercher un client (NOM / PRÉNOM / VILLE)"
            return-object
            item-text="NOM_COMPLET"
            no-filter
            clearable
            @click:clear="onClear()"
            :rules="rules"
            :disabled="disabled"
        >
            <template v-slot:append-outer v-if="!disabled">
                <slot />
            </template>
            <template v-slot:item="{ item }">
                <div>
                    <b>{{ item.NOM_COMPLET }}</b>
                    <p>{{ item.ADRESSE.ADRESSE_COMPLETE }}</p>
                    <v-divider />
                </div>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
    import { get } from 'vuex-pathify'
    import { clientsService } from '~/_services'
    export default {
        props: ['value', 'rules'],
        data() {
            return {
                search: null,
                selectedResult: null,
                results: [],
                isLoading: false,
                debounceTimer: null,
            }
        },
        computed: {
            open: get('dialog/dialogCalendarEdit'),
            rdvCurrentIsNew: get('lolardv/rdvCurrentIsNew'),
            disabled() {
                return !this.rdvCurrentIsNew
            },
        },
        watch: {
            value: {
                handler(newVal, oldVal) {
                    if (newVal?.IDCLIENT !== oldVal?.IDCLIENT) {
                        this.setClient()
                    }
                },
                immediate: true,
            },
            selectedResult: {
                async handler(newVal, oldVal) {
                    if (!newVal || newVal?.IDCLIENT === oldVal?.IDCLIENT) return

                    this.$emit('input', newVal)

                    const res = await clientsService.searchClientById(
                        newVal.IDCLIENT,
                    )
                    this.$emit('input', res)
                },
            },
            search(val) {
                clearTimeout(this.debounceTimer)
                if (!val) return
                if (val.length < 3) return
                if (val === this.selectedResult?.NOM_COMPLET) return

                this.debounceTimer = setTimeout(() => {
                    this.fetchItems(val)
                }, 500)
            },
        },
        methods: {
            setClient() {
                if (!this.value?.PRENOM && !this.value?.NOM) return

                this.selectedResult = {
                    ...this.value,
                    NOM_COMPLET: `${this.value.NOM} ${this.value.PRENOM}`,
                }
                this.results.push(this.selectedResult)
            },
            async fetchItems(val) {
                try {
                    this.isLoading = true

                    const [NOM, PRENOM, VILLE] = val?.split('/')
                    const result = await this.$store.dispatch(
                        'lolaclients/fetchAll',
                        {
                            NOM: NOM ? NOM.trim() : '',
                            PRENOM: PRENOM ? PRENOM.trim() : '',
                            VILLE: VILLE ? VILLE.trim() : '',
                        },
                    )
                    this.results = result.map((item) => ({ ...item }))
                } catch (error) {
                    this.results = []
                } finally {
                    this.isLoading = false
                }
            },
            onClear() {
                this.selectedResult = null
                this.results = []
                this.$emit('clear')
            },
        },
    }
</script>
