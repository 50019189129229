<template>
    <AppDialog
        :dialog.sync="open"
        v-if="open"
        fullscreen
        @close-dialog="close()"
        title="Fiche client"
    >
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>
                    <span>{{ current_client.NOM_COMPLET }}</span>
                </v-list-item-title>

                <v-list-item-subtitle>{{
                    current_client.ADRESSE.ADRESSE
                }}</v-list-item-subtitle>

                <DataEditButtonGroup
                    :address="current_client.ADRESSE"
                    :phone="{
                        PORTABLE: current_client.PORTABLE,
                        PORTABLE2: current_client.PORTABLE2,
                    }"
                    :mail="current_client.MAIL"
                    :observation="current_client.OBSERVATION"
                    :title="current_client.PRENOM"
                    @updateData="updateClient"
                />
            </v-list-item-content>

            <v-list-item-action>
                <V2ProjetsButtonRdv />
                <V2ActionsLink />
            </v-list-item-action>
        </v-list-item>
        <v-divider />
        <h3 class="mt-10">Projets</h3>
        <V2ProjetsLayout
            :loading="loading"
            :projects="current_client.PROJETS"
        />
    </AppDialog>
</template>

<script>
    import { ClientModel } from '~/model'
    import { sync, get } from 'vuex-pathify'
    import { clientsService } from '~/_services'
    export default {
        data() {
            return {
                customerModel: null,
                loading: false,
            }
        },
        computed: {
            open: sync('dialog/dialogClientView'),
            current_client: get('lolaclients/current'),
        },
        watch: {
            current_client: {
                handler(newCustomer) {
                    if (!newCustomer.id) return
                    this.customerModel = new ClientModel(newCustomer)
                },
                immediate: true,
            },
            open: {
                async handler(val) {
                    if (val) {
                        this.loading = true
                        await clientsService
                            .searchClientById(this.current_client.IDCLIENT)
                            .then((res) => {
                                this.$store.commit(
                                    'lolaclients/SET_CURRENT',
                                    res,
                                )
                            })
                        this.loading = false
                    }
                },
            },
        },
        methods: {
            close() {
                this.$store.dispatch('dialog/closeDialogClientView')
            },
            async updateClient(e) {
                try {
                    const data = {}
                    await e.forEach(async (element) => {
                        const field = element.label.toUpperCase()
                        const value = element.tempValue
                        data[field] = value
                    })

                    await clientsService.updateClient({
                        IDCLIENT: this.current_client.IDCLIENT,
                        data,
                    })

                    this.$alert.success('Projet mis à jour')
                } catch (e) {
                    this.$alert.error(e.message)
                }
            },
        },
    }
</script>
