import { AuthError } from "../../utils/CustomError"
import createAxiosInstance from "~/_services/caller"
const Axios = createAxiosInstance()

const login = async (credentials) => {
    try {
        const { data } = await Axios.post("/login", credentials)
        return data
    } catch (error) {
        throw new Error("Identifiants incorrects.")
    }
}

const publicLogin = async (id_client) => {
    const { data } = await Axios.post("/login_public", {
        id_client,
    })
    return data
}

const me = async () => {
    try {
        return await Axios.post("/me")
    } catch (error) {
        throw new Error("Erreur pour recuperer les informations utilisateurs.")
    }
}

const logout = async () => {
    try {
        return await Axios.post("/logout")
    } catch (error) {
        throw new Error("Erreur lors de la deconnexion0")
    }
}

const impersonate_login = async (user) => {
    try {
        const { data } = await Axios.post("/impersonate/" + user.id)
        return data
    } catch (error) {
        throw new AuthError({
            message: `Erreur lors de la connexion en tant que ${user.name}`,
            details: error.message,
        })
    }
}

const impersonate_leave = async (user) => {
    try {
        await Axios.post("/impersonate/leave")
    } catch (error) {
        throw new Error(error.message)
    }
}

const sendEmailResetLink = async (email) => {
    try {
        const { data } = await Axios.post("/password/email", email)
        return data
    } catch (error) {
        const code = error.response.data.message
        if (code === "passwords.throttled") {
            throw new Error("Trop de tentatives. Veuillez réessayer plus tard.")
        }
        if (code === "passwords.user") {
            throw new Error(
                "Aucun utilisateur trouvé avec cette adresse email.",
            )
        }
        throw new Error(
            "Une erreur est survenue. Veuillez contactez Solis System.",
        )
    }
}

const resetPassword = async (credentials) => {
    try {
        const { data } = await Axios.post("/password/reset", credentials)
        return data
    } catch (error) {
        throw new Error("Erreur lors de la réinitialisation du mot de passe.")
    }
}

export const accountService = {
    login,
    publicLogin,
    me,
    logout,
    impersonate_login,
    impersonate_leave,
    sendEmailResetLink,
    resetPassword,
}
