import createAxiosInstance from "~/_services/caller"
const Axios = createAxiosInstance()

const searchClient = async (searchParams) => {
    try {
        const { data } = await Axios.post("/clients/search", searchParams)
        return data
    } catch (error) {
        throw new Error(error.message)
    }
}
const searchClientById = async (id) => {
    try {
        const { data } = await Axios.get(`/clients/${id}`)
        return data
    } catch (error) {
        throw new Error(error.message)
    }
}

const updateClient = async (params) => {
    try {
        const response = await Axios.put(
            "/client/" + params.IDCLIENT,
            params.data,
        )
        return response.data.PROJET
    } catch (error) {
        throw new Error(
            `Impossible de mettre à jour le projet ${error.message}`,
        )
    }
}

export const clientsService = {
    searchClient,
    searchClientById,
    updateClient,
}
