import Vue from "vue"
import App from "~/App.vue"
import VueGtag from "vue-gtag"
import store from "~/store"
import router from "~/router"
import vuetify from "~/plugins/vuetify"
import initializeSentry from "~/plugins/sentry"
import registerServiceWorker from "~/plugins/serviceWorker"
import Notifications from "vue-notification"
import { sync } from "vuex-router-sync"

import "./sass/app.scss"

// Configuration
import "~/config/common/config.js"
import "~/config/filters.js"
import "~/config/directives.js"
import "~/config/extends.js"
import "~/config/viewer.js"

// Plugins
import "~/plugins/moment"
import "~/plugins/leaflet"
import "~/plugins/device"
import "~/plugins/gmaps"
import "~/plugins/alerts"
import "~/plugins/sweatalert"

Vue.config.errorHandler = (err, vm, info) => {
    console.error("Global Vue Error:", err)
}
// register globally
Vue.use(Notifications)
Vue.use(VueGtag, {
    config: { id: import.meta.env.VITE_GTAG },
})
Vue.config.devtools = import.meta.env.VITE_DEBUG_MODE || false

sync(store, router)
initializeSentry(Vue)
registerServiceWorker()

new Vue({
    store,
    vuetify,
    router,
    render: (h) => h(App),
}).$mount("#app")
